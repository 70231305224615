export const BED_FRAMES = 'Bed Frames'
export const PLATFORM_BASES = 'Platform Bases'
export const PILLOWCASES_AND_SHAMS = 'Pillowcases & Shams'
export const MATTRESS_TOPPERS = 'Mattress Toppers'
export const DUVETS = 'Duvets'
export const RUGS = 'Rugs'
export const CANDLES = 'Candles'
export const MATTRESS_PADS = 'Mattress Pads'
export const SHEETS = 'Sheets'
export const COMFORTERS = 'Comforters'
export const BLANKETS = 'Blankets'
export const QUILTS = 'Quilts'
export const EYE_MASKS = 'Sleep Accessories'
export const TOWELS = 'Towels'
export const NIGHTSTANDS = 'Nightstands'
export const LIGHTING = 'Lighting'
export const FOUNDATIONS = 'Foundations'
export const ADJUSTABLE_BASES = 'Adjustable Bases'
export const BENCHES = 'Benches'
export const CHAIRS = 'Chairs'
